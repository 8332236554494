<script lang="ts">
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { Switch } from '$lib/components/ui/switch/index.js';
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import { DateTime } from 'luxon';
    import { Banknotes, Icon, Sparkles } from 'svelte-hero-icons';
    import ApexCharts from 'apexcharts';
    import axios from 'axios';
    import 'flatpickr/dist/flatpickr.css';
    import pointsLight from '../../../public/svg/points_light.svg';
    import pointsDark from '../../../public/svg/points_dark.svg';
    import CalendarIcon from 'lucide-svelte/icons/calendar';
    import { type DateValue } from '@internationalized/date';
    import { cn } from '$lib/utils.js';
    import { Button } from '$lib/components/ui/button/index.js';
    import { RangeCalendar } from '$lib/components/ui/range-calendar/index.js';
    import * as Popover from '$lib/components/ui/popover/index.js';

    let currentRange: { start?: DateValue; end?: DateValue } = { start: undefined, end: undefined };

    let formattedRange: { start: string | null; end: string | null } = { start: null, end: null };

    export let cookies: string;

    export let theme: string;

    export let withdrawalPoints: number;

    let scale: string = 'd';

    let amount: number = 7;

    let offset: number = 0;

    let currentRoute = '';

    let chartContainer: HTMLElement | null = null;

    let chart: any;

    let selectedTimeframe: string = 'Last 7 Days';

    let timeframeValue: string = 'sevenDays';

    let revenuePeriodStats: string = '';

    let revenuePeriodValue: string = '';

    let revenuePeriodPercent: string = '';

    let revenuePeriodPercentColor: string = '';

    let revenuePeriodPercentBg: string = '';

    let isCustomRange: boolean = false;

    let isChartLoading: boolean = false;

    let isValueLoading: boolean = false;

    let isPointsMultiplierActive: boolean = false;

    let chartOptions = {
        series: [{ data: [] }],
        chart: {
            height: 400,
            type: 'line',
            toolbar: {
                show: false,
            },
            zoom: { enabled: false },
        },

        stroke: { curve: 'straight' },

        xaxis: {
            type: 'category',
            categories: [],
            labels: {
                formatter: function (val: any) {
                    return Math.round(val);
                },
            },
        },
        yaxis: {
            opposite: false,
            labels: {
                formatter: function (val: any) {
                    return Math.round(val);
                },
            },
        },
        legend: { horizontalAlign: 'left' },
    };

    const currentDate: Date = new Date();

    const currentYear: number = currentDate.getFullYear();

    const currentMonthNumber: number = currentDate.getMonth();

    const prevYear: number = currentYear - 1;

    const currentMonth: string = currentDate.toLocaleString('default', { month: 'long' });

    const prevMonth: string = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).toLocaleString(
        'default',
        {
            month: 'long',
        }
    );

    const prevPMonth: string = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1).toLocaleString(
        'default',
        {
            month: 'long',
        }
    );

    const getMonthRange = (year: number, month: number): { start: Date; end: Date } => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return { start: firstDay, end: lastDay };
    };
    const currentMonthRange: { start: Date; end: Date } = getMonthRange(currentYear, currentMonthNumber);

    const prevMonthRange: { start: Date; end: Date } = getMonthRange(currentYear, currentMonthNumber - 1);

    const monthbeforePrevRange: { start: Date; end: Date } = getMonthRange(currentYear, currentMonthNumber - 2);

    const formatDate = (date: Date): string => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

    const predefinedTimes = (): void => {
        let startDate: string, endDate: string;

        switch (timeframeValue) {
            case 'currentYear':
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
            case 'prevYear':
                startDate = `${prevYear}-01-01`;
                endDate = `${prevYear}-12-31`;
                break;
            case 'currentMonth':
                startDate = formatDate(currentMonthRange.start);
                endDate = formatDate(currentMonthRange.end);
                break;
            case 'prevMonth':
                startDate = formatDate(prevMonthRange.start);
                endDate = formatDate(prevMonthRange.end);
                break;
            case 'prevPMonth':
                startDate = formatDate(monthbeforePrevRange.start);
                endDate = formatDate(monthbeforePrevRange.end);
                break;
            default:
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
        }

        updateRangeAndChart(startDate, endDate);
    };

    const updateTimeframe = (selectedValue: string, selectedLabel: string): void => {
        selectedTimeframe = selectedLabel;
        timeframeValue = selectedValue;

        switch (selectedValue) {
            case 'sevenDays':
                scale = 'd';
                amount = 7;
                break;
            case 'oneMonth':
                scale = 'd';
                amount = 30;
                break;
            case 'threeMonths':
                scale = 'd';
                amount = 90;
                break;
            case 'oneYear':
                scale = 'm';
                amount = 12;
                break;
            default:
                scale = 'd';
                amount = 7;
                break;
        }

        handleItemChange();
    };

    const updateRangeAndChart = (startDate: string, endDate: string): void => {
        formattedRange.start = startDate;
        formattedRange.end = endDate;

        if (timeframeValue !== 'custom') {
            updateChartCustom();
        }
    };

    const handleItemChange = (): void => {
        if (timeframeValue === 'custom') {
            if (currentRange.start && currentRange.end) {
                updateChartCustom();
            }
        } else {
            if (['currentYear', 'prevYear', 'currentMonth', 'prevMonth', 'prevPMonth'].includes(timeframeValue)) {
                predefinedTimes();
            } else {
                updateChart();
            }
        }
    };

    onMount(() => {
        currentRoute = window.location.pathname;
        chart = new ApexCharts(chartContainer, chartOptions);
        chart.render();
        handleItemChange();
    });

    const timeframe = [
        { value: 'sevenDays', label: 'Last 7 Days' },
        { value: 'oneMonth', label: 'Last Month' },
        { value: 'threeMonths', label: 'Last 3 Month' },
        { value: 'oneYear', label: 'Last Year' },
        { value: 'currentYear', label: currentYear.toString() },
        { value: 'prevYear', label: prevYear.toString() },
        { value: 'currentMonth', label: currentMonth },
        { value: 'prevMonth', label: prevMonth },
        { value: 'prevPMonth', label: prevPMonth },
        { value: 'custom', label: 'Custom' },
    ];

    const statsUpdate = (stats: number, percent: number, pointsDisplay = false): void => {
        let percentround = Math.ceil(percent);

        const multiplier = isPointsMultiplierActive ? 1 : withdrawalPoints;
        if (pointsDisplay) {
            revenuePeriodValue = `${Math.ceil(stats * multiplier)}`;
        } else {
            revenuePeriodValue = `${Math.ceil(stats)}`;
        }

        let color = percent > 0 ? '#00FFA3' : percent < 0 ? '#ff0000' : 'rgba(128, 128, 128, 1)';
        let backgroundColor = percent < 0 ? 'rgba(255, 20, 64, 0.12)' : percent > 0 ? '' : 'rgba(117,117,117,0.12)';
        const sign = percent > 0 ? '+' : '';

        revenuePeriodPercent = `${sign}${percentround}%`;
        revenuePeriodPercentColor = color;
        revenuePeriodPercentBg = backgroundColor;
    };

    const processChartData = (data: any, toggleState: boolean) => {
        const multiplier = toggleState ? 1 : withdrawalPoints;

        return {
            bid: data?.bid.map((value: number) => value * multiplier),
            deposit: data?.deposit.map((value: number) => value * multiplier),
            postUnlock: data['post-unlock']?.map((value: number) => value * multiplier),
            messageUnlock: data['message-unlock']?.map((value: number) => value * multiplier),
            reward: data?.reward.map((value: number) => value * multiplier),
            subscription: data?.subscription.map((value: number) => value * multiplier),
            tip: data?.tip.map((value: number) => value * multiplier),
        };
    };

    const showLoaders = (): void => {
        isChartLoading = true;
        isValueLoading = true;
    };

    const hideLoaders = (): void => {
        isChartLoading = false;
        isValueLoading = false;
    };

    const updateChart = async (): Promise<void> => {
        revenuePeriodStats = `${amount}${scale}`;

        let apiUrl = `/analytics/revenue/v1?scale=${scale}&amount=${amount}&offset=${offset}`;

        showLoaders();

        isCustomRange = false;

        try {
            const response = await axios.get(apiUrl);
            const data = response.data;

            hideLoaders();

            statsUpdate(data.last, data.growth, !isPointsMultiplierActive);

            const processedData = processChartData(data.data, isPointsMultiplierActive);

            chart?.updateOptions({
                xaxis: {
                    categories: data.labels.map((label: any) => {
                        return label[0];
                    }),
                },
            });

            chart?.updateSeries([
                { name: 'Bid', data: processedData.bid },
                { name: 'Deposit', data: processedData.deposit },
                { name: 'Post Unlock', data: processedData.postUnlock },
                { name: 'Message Unlock', data: processedData.messageUnlock },
                { name: 'Reward', data: processedData.reward },
                { name: 'Subscription', data: processedData.subscription },
                { name: 'Tip', data: processedData.tip },
            ]);
        } catch (error) {
            console.error('Error Fetching', error);
        } finally {
            hideLoaders();
        }
    };

    const updateChartCustom = async (): Promise<void> => {
        revenuePeriodStats = `${formattedRange.start} - ${formattedRange.end}`;

        isCustomRange = true;

        if (!formattedRange.start || !formattedRange.end) return;

        const fullStart = formattedRange.start + ' 00:00:00';
        const fullEnd = formattedRange.end + ' 23:59:59';

        let apiUrl = `/analytics/revenue/v1?start_date=${encodeURIComponent(fullStart)}&end_date=${encodeURIComponent(fullEnd)}`;

        showLoaders();

        try {
            const response = await axios.get(apiUrl);
            const data = response.data;
            hideLoaders();

            statsUpdate(data.last, data.growth, !isPointsMultiplierActive);

            const processedData = processChartData(data.data, isPointsMultiplierActive);

            chart?.updateOptions({
                xaxis: {
                    categories: data.labels.map((label: any) => {
                        return label[0];
                    }),
                },
            });

            chart?.updateSeries([
                { name: 'Bid', data: processedData.bid },
                { name: 'Deposit', data: processedData.deposit },
                { name: 'Post Unlock', data: processedData.postUnlock },
                { name: 'Message Unlock', data: processedData.messageUnlock },
                { name: 'Reward', data: processedData.reward },
                { name: 'Subscription', data: processedData.subscription },
                { name: 'Tip', data: processedData.tip },
            ]);
        } catch (error) {
            console.error('Error Fetching', error);
        } finally {
            hideLoaders();
        }
    };

    function toJSDate(dateValue: DateValue): Date {
        return new Date(dateValue.year, dateValue.month - 1, dateValue.day);
    }

    const handleDate = (start: DateValue | undefined, end: DateValue | undefined): void => {
        currentRange.start = start;
        currentRange.end = end;

        if (start && end) {
            const startJS = toJSDate(start);
            const endJS = toJSDate(end);

            formattedRange.start = DateTime.fromJSDate(startJS).toFormat('yyyy-MM-dd');
            formattedRange.end = DateTime.fromJSDate(endJS).toFormat('yyyy-MM-dd');

            updateChartCustom();
        }
    };

    $: if (isPointsMultiplierActive !== undefined) {
        handleItemChange();
    }
</script>

<div class="min-vh-100 pr-0 pt-4">
    <div class="px-4">
        <h5 class="text-truncate mb-0 font-bold dark:text-white {cookies}">Analytics Dashboard</h5>
    </div>

    <div class="inline-border-tabs my-3">
        <nav class="nav nav-pills nav-justified text-bold mt-3">
            <a
                class="nav-item nav-link flex items-center justify-center gap-x-1 {currentRoute ===
                `/analytics/engagement`
                    ? 'active'
                    : ''}"
                href="{`/analytics/engagement`}"
                use:inertia
            >
                <Icon src="{Sparkles}" size="{'22px'}" />
                Engagement
            </a>
            <a
                class="nav-item nav-link flex items-center justify-center gap-x-1 {currentRoute === `/analytics/revenue`
                    ? 'active'
                    : ''}"
                href="{`/analytics/revenue`}"
                use:inertia
            >
                <Icon src="{Banknotes}" size="{'22px'}" />
                Revenue
            </a>
        </nav>
    </div>

    <div class="px-2">
        <div class="impression mt-4 flex items-center">
            <div class="analytic-type">
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger class="flex rounded-md bg-neutral-100 px-4 py-2 dark:bg-neutral-800"
                        >{selectedTimeframe}</DropdownMenu.Trigger
                    >
                    <DropdownMenu.Content>
                        <DropdownMenu.Group>
                            {#each timeframe as time}
                                <DropdownMenu.Item on:click="{() => updateTimeframe(time.value, time.label)}">
                                    {time.label}
                                </DropdownMenu.Item>
                            {/each}
                        </DropdownMenu.Group>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            </div>

            <div class="analytic-type ml-4 flex items-center">
                <img src="{theme === 'light' ? pointsLight : pointsDark}" class="h-5 w-5" alt="" srcset="" />
                &nbsp;
                <Switch id="airplane-mode" bind:checked="{isPointsMultiplierActive}" on:change="{handleItemChange}" />
                &nbsp;
                <span class="font-mona-expanded text-lg font-bold text-black dark:!text-white">$</span>
            </div>

            &nbsp; &nbsp;

            {#if timeframeValue === 'custom'}
                <Popover.Root openFocus>
                    <Popover.Trigger asChild let:builder>
                        <Button
                            variant="outline"
                            class="{cn(
                                'w-[300px] justify-center text-center font-normal',
                                !currentRange && 'text-muted-foreground'
                            )}"
                            builders="{[builder]}"
                        >
                            <CalendarIcon class="mr-2 h-4 w-4" />
                            {#if formattedRange?.start && formattedRange?.end}
                                {formattedRange.start} - {formattedRange.end}
                            {:else}
                                Pick a date
                            {/if}
                        </Button>
                    </Popover.Trigger>

                    <Popover.Content class="w-auto p-0" align="start">
                        <RangeCalendar
                            bind:value="{currentRange}"
                            initialFocus
                            numberOfMonths="{2}"
                            placeholder="{currentRange?.start}"
                            onValueChange="{(e) => {
                                handleDate(e.start, e.end);
                            }}"
                        />
                    </Popover.Content>
                </Popover.Root>
            {/if}
        </div>

        <div class="stats mt-3">
            <span class="revenue-period-stats !flex !items-center text-lg text-black dark:!text-white">
                {revenuePeriodStats}
            </span>

            <div class="d-flex stats-values relative items-center">
                {#if isValueLoading}
                    <div class="absolute bottom-0 left-0 top-0 z-50 w-full rounded-lg border-2">
                        <div class="h-full w-full animate-pulse">
                            <div class="h-full w-full rounded-lg bg-gray-200"></div>
                        </div>
                    </div>
                {/if}

                <span
                    class="revenue-period-value font-mona-expanded flex items-center text-6xl font-black text-black dark:!text-white"
                >
                    {revenuePeriodValue}
                    {#if isPointsMultiplierActive}
                        <span class="ml-2">$</span>
                    {:else}
                        <img
                            src="{theme === 'light' ? pointsLight : pointsDark}"
                            class="ml-2 h-10 w-10"
                            alt="Points Icon"
                            aria-label="Points"
                        />
                    {/if}
                </span>

                &nbsp;&nbsp;&nbsp;

                <span
                    class="revenue-period-percent rounded-md px-2 py-1 text-lg font-bold text-teal-400"
                    style="background-color: {revenuePeriodPercentBg}; color: {revenuePeriodPercentColor};"
                >
                    {revenuePeriodPercent}
                </span>
            </div>
        </div>
        <div id="chart-container" style="position: relative; top: 4px">
            {#if isChartLoading}
                <div class="absolute bottom-0 left-0 right-0 top-0 z-50 !h-full !w-full rounded-lg">
                    <div class="h-full w-full animate-pulse">
                        <div class="!h-full !w-full rounded-lg bg-gray-200"></div>
                    </div>
                </div>
            {/if}
            <div>
                <div bind:this="{chartContainer}" id="chart"></div>
            </div>
        </div>
    </div>
</div>
